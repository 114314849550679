<template>
  <section class="emotional-words-wrap">
    <el-table
      :data="tableData"
      border max-height="calc(100% - 40px)"
      style="width: 100%">
      <el-table-column  :resizable="false"
        prop="subjectName"
        align="center"
        label="主题名称">
      </el-table-column>
      <el-table-column  :resizable="false"
        prop="coreWords"
        align="center"
        label="核心词">
      </el-table-column>
      <el-table-column  :resizable="false"
        prop="subject"
        align="center"
        label="主体词">
      </el-table-column>
      <el-table-column  :resizable="false"
        prop="ambiguity"
        align="center"
        label="歧义词">
      </el-table-column>
      <el-table-column  :resizable="false"
        prop="createTime"
        align="center"
        label="创建时间">
      </el-table-column>
    </el-table>
    <section class="list-bottom">
      <span class="count">共{{count}}条记录</span>
      <el-pagination
        :current-page="currentPage4"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="100"
        layout="total, sizes, prev, pager, next, jumper"
        :total="count">
      </el-pagination>
    </section>
  </section>
</template>

<script>
import eventHub from '@/utils/eventHub'
export default {
  mounted () {
    eventHub.$emit('msg', '情感词设置')
  },
  data () {
    return ({
      count: 2,
      tableData: [
        {
          subjectName: '万科',
          coreWords: '万科地产，万科新闻，宁波万科',
          subject: '万科地产，万科新闻，宁波万科',
          ambiguity: '无',
          createTime: '2019-11-06 15:32'
        },
        {
          subjectName: '万科宁波住宅',
          coreWords: '宁波白石湖东，宁波滨河道',
          subject: '宁波白石湖东，宁波滨河道',
          ambiguity: '白石湖东',
          createTime: '2019-11-05 16:32'
        },
        {
          subjectName: '竞对龙湖',
          coreWords: '宁波龙湖+龙湖咨询',
          subject: '宁波龙湖+龙湖咨询',
          ambiguity: '无',
          createTime: '2019-11-04 15:32'
        }
      ],
      currentPage1: 5,
      currentPage2: 5,
      currentPage3: 5,
      currentPage4: 4
    })
  }
}
</script>

<style lang="scss">
.emotional-words-wrap{
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 8px;
  padding: 20px 20px;
  box-shadow:0px 1px 13px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  .list-bottom {
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 20px;
    left: 20px;
    right: 20px;
    .count {
      line-height: 32px;
    }
  }
}
</style>
